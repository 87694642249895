var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Input from "@material-ui/core/Input";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
var EditableTableCell = function (props) {
    return (_jsx(TableCell, __assign({ className: props.className }, { children: props.isEditing ? (_jsx(Input, { disableUnderline: true, value: props.row[props.name], name: props.name, onBlur: function (e) { return props.onCellBlur(e, props.row); }, onChange: function (e) { return props.onCellChange(e, props.row); } }, void 0)) : (props.row[props.name]) }), void 0));
};
export { EditableTableCell };
