import { combineReducers, createStore } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";
// import { CountActionTypes, countStore } from "./countExample";
// import { NameActionTypes, nameStore } from "./nameExample";
import { userPrefsStore } from "./userPrefs";
var storageListeners = new Map();
window.addEventListener("storage", function (e) {
    var key = e.key, newValue = e.newValue;
    if (key && newValue) {
        var assign = storageListeners.get(key);
        if (assign) {
            assign(newValue);
        }
    }
});
//
// MANUAL STEP - you need to manually check to make sure the string keys here
// match the string keys found in the various individal store storageKey properties
// (it should be possible to set them here using that property but I cant get
// typescript to be ok with doing so).
//
var rootReducer = combineReducers({
    // "count": countStore.reducer,
    // "name": nameStore.reducer,
    userPrefs: userPrefsStore.reducer,
});
// const countSelector = countStore.selector;
// const useReduxCount = [countSelector, countStore.actionsFactory, countStore.hasPersistence ? countStore.storageKey : ""] as [
//     typeof countSelector,
//     typeof countStore.actionsFactory,
//     string,
// ];
// // consume as
// //     import { Dispatch } from "react";
// //     import { useDispatch, useSelector } from "react-redux";
// //     import { ReduxState, CountActions, useReduxCount } from "...../core/store";
// //     const [countSelector, countActionsFactory, countStorageKey] = useReduxCount;
// //     const countDispatch = useDispatch<Dispatch<CountActions>>();
// //     const countActions = countActionsFactory(countDispatch);
// //     const { count } = useSelector((state: ReduxState) => countSelector<ReduxState>(state));
// //     if (countStorageKey) { storageListeners.set(countStorageKey, countActions.Assign); };
// //     ...
// //     console.log(count);
// //     countActions.someAction(foo);
// const nameSelector = nameStore.selector;
// const useReduxName = [nameSelector, nameStore.actionsFactory, nameStore.hasPersistence ? nameStore.storageKey : ""] as [
//     typeof nameSelector,
//     typeof nameStore.actionsFactory,
//     string,
// ];
// // consume as
// //     import { Dispatch } from "react";
// //     import { useDispatch, useSelector } from "react-redux";
// //     import { ReduxState, NameActions, useReduxName } from "...../core/store";
// //     const [nameSelector, nameActionsFactory, nameStorageKey] = useReduxName;
// //     const nameDispatch = useDispatch<Dispatch<NameActions>>();
// //     const nameActions = nameActionsFactory(nameDispatch);
// //     const { name } = useSelector((state: ReduxState) => nameSelector<ReduxState>(state));
// //     if (nameStorageKey) { storageListeners.set(nameStorageKey, nameActions.Assign); };
// //     ...
// //     console.log(name);
// //     nameActions.someAction(foo);
var userPrefsSelector = userPrefsStore.selector;
var useReduxUserPrefs = [
    userPrefsSelector,
    userPrefsStore.actionsFactory,
    userPrefsStore.hasPersistance ? userPrefsStore.storageKey : "",
];
// consume as
//     import { Dispatch } from "react";
//     import { useDispatch, useSelector } from "react-redux";
//     import { storageListeners, ReduxState, UserPrefsActions, useReduxUserPrefs } from "...../core/store";
//     const [userPrefsSelector, userPrefsActionsFactory, userPrefsStorageKey] = useReduxUserPrefs;
//     const userPrefsDispatch = useDispatch<Dispatch<UserPrefsActions>>();
//     const userPrefsActions = userPrefsActionsFactory(userPrefsDispatch);
//     const userPrefs = useSelector((state: ReduxState) => userPrefsSelector<ReduxState>(state));
//     if (userPrefsStorageKey) { storageListeners.set(userPrefsStorageKey, userPrefsActions.Assign); };
//     ...
//     console.log(username);
//     userPrefsActions.someAction(foo);
var reduxStore = createStore(rootReducer, devToolsEnhancer({}));
export { 
// ReduxState,
// // CountActions,
// // NameActions,
// UserPrefsActions,
storageListeners, reduxStore, 
// useReduxCount,
// useReduxName,
useReduxUserPrefs, };
