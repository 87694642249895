var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { kAttrSourcePath } from "../../core/types";
var AssetSummary = function (props) {
    return (_jsx("div", { children: _jsxs(Typography, __assign({ variant: "subtitle1", gutterBottom: true }, { children: [_jsx("span", __assign({ style: { padding: "10px" } }, { children: props.product.handle }), void 0),
                _jsx("span", __assign({ style: { padding: "20px" } }, { children: props.product.attr[kAttrSourcePath] }), void 0)] }), void 0) }, void 0));
};
export { AssetSummary };
