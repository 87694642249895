var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import React from "react";
var useStyles = makeStyles(function (theme) { return ({
    gridList: {
        width: "100%",
        height: "100%",
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: "translateZ(0)",
    },
    thumb: {
        height: "100%",
        width: "100%",
        objectFit: "contain",
    },
    title: {
        padding: "20px",
    },
    titleBar: {
        background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
            "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    icon: {
        color: "white",
    },
}); });
var AssetImages = function (props) {
    var classes = useStyles();
    var setHero = function (product, imageId) {
        var newProduct = __assign(__assign({}, product), { heroImage: imageId });
        props.modifyProduct(product, newProduct);
    };
    return (_jsx(GridList, __assign({ cellHeight: 150, cols: 6, spacing: 1, className: classes.gridList }, { children: Object.keys(props.product.images).map(function (id, index1) {
            var img = props.product.images[id];
            var alt = img.thumb.split("/").pop();
            return (_jsxs(GridListTile, __assign({ cols: 1, rows: 1 }, { children: [_jsx("img", { src: img.thumb.replace("+", "%2B"), alt: alt, className: classes.thumb }, void 0),
                    _jsx(GridListTileBar, { title: "", titlePosition: "top", actionIcon: _jsx(IconButton, __assign({ "aria-label": "star " + alt, className: classes.icon, onClick: function () { return setHero(props.product, id); } }, { children: props.product.heroImage === id ? (_jsx(StarIcon, {}, void 0)) : (_jsx(StarBorderIcon, {}, void 0)) }), void 0), actionPosition: "left", className: classes.titleBar }, void 0)] }), index1));
        }) }), void 0));
};
export { AssetImages };
