var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//
// NOTE: model-viewer requires @webcomponents/custom-elements polyfill on older firefox and Edge
//       polyfill needs to be loaded by adding the following to App.tsx:
//
//  import "@webcomponents/custom-elements"; // required by model-viewer on older browsers
//
import "@google/model-viewer";
import ButtonBase from "@material-ui/core/ButtonBase";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import GitHubForkRibbon from "react-github-fork-ribbon";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { kFilePreviewGltf } from "../../core/types";
var useStyles = makeStyles(function (theme) { return ({
    thumb: {
        height: "100%",
        width: "100%",
        objectFit: "contain",
    },
}); });
var AssetImage = function (props) {
    var classes = useStyles();
    var img = props.product.images[props.product.heroImage];
    if (props.product.heroImage === "" || img === undefined) {
        return (_jsx("div", __assign({ className: classes.thumb }, { children: 
            // @ts-ignore
            _jsx(LazyLoadImage, { alt: "loading", height: "100%", src: "", width: "100%" }, void 0) }), void 0));
    }
    if (props.lite) {
        if (props.linkTo) {
            return (_jsx("div", __assign({ className: classes.thumb }, { children: _jsx(Link, __assign({ to: props.linkTo }, { children: 
                    // @ts-ignore
                    _jsx(LazyLoadImage, { alt: props.product.title + " hero", height: "100%", src: img.thumb, width: "100%" }, void 0) }), void 0) }), void 0));
        }
        if (props.onClick) {
            return (_jsx("div", __assign({ className: classes.thumb }, { children: _jsx(ButtonBase, __assign({ onClick: function () {
                        return props.onClick && props.onClick(props.product);
                    } }, { children: img && (
                    // @ts-ignore
                    _jsx(LazyLoadImage, { alt: props.product.title + " hero", height: "100%", src: img.thumb, width: "100%" }, void 0)) }), void 0) }), void 0));
        }
        return (_jsx("div", __assign({ className: classes.thumb }, { children: img && (
            // @ts-ignore
            _jsx(LazyLoadImage, { alt: props.product.title + " hero", height: "100%", src: img.thumb, width: "100%" }, void 0)) }), void 0));
    }
    return (_jsxs("div", __assign({ className: props.className, style: __assign(__assign({}, props.style), { height: props.height || "400px", position: "relative" }) }, { children: [_jsx(GitHubForkRibbon, __assign({ href: "//backlotlabs.com", target: "_blank", position: "right" }, { children: "Courtesy of BacklotLabs" }), void 0),
            _jsx("model-viewer", { style: { width: "100%", height: props.height || "400px" }, "camera-controls": true, preload: true, reveal: "interaction", poster: img.poster.replace("+", "%2B"), src: props.product.files[kFilePreviewGltf], exposure: "0.3" }, void 0)] }), void 0));
};
export { AssetImage };
