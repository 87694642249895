var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
var AssetTitle = function (props) {
    var _a = useState(false), editing = _a[0], setEditing = _a[1];
    var _b = useState(""), value = _b[0], setValue = _b[1];
    var startEdit = function () {
        setValue(props.product.title);
        setEditing(true);
    };
    var doneEdit = function () {
        var newProduct = JSON.parse(JSON.stringify(props.product));
        newProduct.title = value;
        props.modifyProduct(props.product, newProduct);
        setValue("");
        setEditing(false);
    };
    var cancelEdit = function () {
        setValue("");
        setEditing(false);
    };
    var handleChange = function (event) {
        setValue(event.target.value);
    };
    if (editing) {
        return (_jsxs("div", __assign({ style: { padding: "20px" } }, { children: [_jsx(TextField, { value: value, onChange: handleChange }, void 0),
                _jsx(IconButton, __assign({ "aria-label": "edit", onClick: doneEdit }, { children: _jsx(CheckCircleIcon, {}, void 0) }), void 0),
                _jsx(IconButton, __assign({ "aria-label": "edit", onClick: cancelEdit }, { children: _jsx(CancelIcon, {}, void 0) }), void 0)] }), void 0));
    }
    return (_jsxs("span", { children: [_jsx(Typography, __assign({ component: "h2", variant: "h6", color: "primary", gutterBottom: true, className: props.className, style: { display: "inline-flex" } }, { children: props.product.title || "untitled" }), void 0),
            _jsx(IconButton, __assign({ "aria-label": "edit", onClick: startEdit }, { children: _jsx(EditIcon, {}, void 0) }), void 0)] }, void 0));
};
export { AssetTitle };
