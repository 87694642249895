var config = {
    apiUrlBase: "",
};
var getConfig = function () {
    return config;
};
var setConfig = function (arg) {
    Object.keys(arg).forEach(function (key) {
        config[key] = arg[key];
    });
};
export { getConfig, setConfig, };
