var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/EditOutlined";
import DraggerIcon from "@material-ui/icons/Menu";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import React, { useState, } from "react";
import { EditableTableCell } from "./EditableTableCell";
var useStyles = makeStyles({
    tableCell: {
        width: 180,
        height: 40,
    },
});
//
// June 2020 - I cant figure out why eslint fix insists on giving this a signature of <T extends Record<string, any>>
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var EditableTableRow = function (props) {
    var classes = useStyles();
    var _a = useState(undefined), modified = _a[0], setModified = _a[1];
    var done = function () {
        if (modified) {
            props.onChanged(props.index, modified);
            setModified(undefined);
        }
    };
    var edit = function () {
        setModified(props.row);
    };
    var revert = function () {
        setModified(undefined);
    };
    var remove = function () {
        props.onDelete(props.index);
    };
    var blurred = function (e, row) {
        var _a;
        if (props.editingButtons) {
            return;
        }
        var value = e.target.value;
        var name = e.target.name;
        var newRow = __assign(__assign({}, row), (_a = {}, _a[name] = value, _a));
        props.onChanged(props.index, newRow);
    };
    var changed = function (e, row) {
        var _a;
        var value = e.target.value;
        var name = e.target.name;
        var newRow = __assign(__assign({}, row), (_a = {}, _a[name] = value, _a));
        setModified(newRow);
    };
    var isEditing = modified !== undefined;
    var current = modified === undefined ? props.row : modified;
    return (
    // NOTE: as of June 2020 TableRow does accept a component prop but typescript
    // cant figure that out, so ignore the typescript "error" in the next line
    // @ts-ignore
    _jsxs(TableRow, __assign({ component: props.component }, { children: [props.dragReorder && (_jsx(TableCell, { children: _jsx(IconButton, __assign({ "aria-label": "edit", onClick: edit }, { children: _jsx(DraggerIcon, {}, void 0) }), void 0) }, void 0)),
            props.names.map(function (name, index) { return (_jsx(EditableTableCell, { isEditing: !props.editingButtons || isEditing, name: name, row: current, onCellBlur: blurred, onCellChange: changed, className: classes.tableCell }, index)); }),
            props.editingButtons ? (_jsx(TableCell, { children: isEditing ? (_jsxs(_Fragment, { children: [_jsx(IconButton, __assign({ "aria-label": "done", onClick: done }, { children: _jsx(DoneIcon, {}, void 0) }), void 0),
                        _jsx(IconButton, __assign({ "aria-label": "revert", onClick: revert }, { children: _jsx(RevertIcon, {}, void 0) }), void 0)] }, void 0)) : (_jsxs(_Fragment, { children: [_jsx(IconButton, __assign({ "aria-label": "edit", onClick: edit }, { children: _jsx(EditIcon, {}, void 0) }), void 0),
                        _jsx(IconButton, __assign({ "aria-label": "delete", onClick: remove }, { children: _jsx(DeleteIcon, {}, void 0) }), void 0)] }, void 0)) }, void 0)) : (_jsx(TableCell, { children: _jsx(IconButton, __assign({ "aria-label": "delete", onClick: remove }, { children: _jsx(DeleteIcon, {}, void 0) }), void 0) }, void 0))] }), void 0));
};
export { EditableTableRow };
