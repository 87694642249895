var kAttrSourcePath = "Source File";
var kFileFullFbx = "full-fbx";
var kFileFullGltf = "full-gltf";
var kFileFullObj = "full-obj";
var kFilePreviewFbx = "preview-fbx";
var kFilePreviewGltf = "preview-gltf";
var kFilePreviewObj = "preview-obj";
var axisInvert = {
    "-x": "x",
    "-y": "y",
    "-z": "z",
    unknown: "unknown",
    x: "-x",
    y: "-y",
    z: "-z",
};
var kFileSrcNone = {
    file: "",
};
var kImageSrcNone = {
    poster: "",
    thumb: "",
};
var kProductEmpty = function (handle) {
    var emptyProduct = {
        attr: {},
        files: {},
        handle: handle,
        heroImage: "",
        images: {},
        meta: [],
        tags: [],
        title: "(loading)",
    };
    return emptyProduct;
};
var kSearchResultsZero = {
    query: "",
    hits: [],
    limit: 1,
    offset: 0,
};
export { 
// axis,
axisInvert, 
// IApp,
// IFileBinary,
// IImageSrc,
// IProduct,
// IProductLite,
// IProductSearch,
// ISearchResults,
// ISourceFile,
// IUserPrefsState,
kAttrSourcePath, kFileFullFbx, kFileFullGltf, kFileFullObj, kFilePreviewFbx, kFilePreviewGltf, kFilePreviewObj, kFileSrcNone, kImageSrcNone, kProductEmpty, kSearchResultsZero, };
