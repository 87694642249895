var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import RootRef from "@material-ui/core/RootRef";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import React from "react";
import { DragDropContext, Draggable, Droppable, } from "react-beautiful-dnd";
import { EditableTableRow } from "./EditableTableRow";
var getItemStyle = function (isDragging, draggableStyle) { return (__assign(__assign({}, draggableStyle), (isDragging && {
    background: "rgb(235,235,235)",
}))); };
var getTableStyle = function (isDraggingOver) { return ({
//background: isDraggingOver ? "lightblue" : "lightgrey",
}); };
var DraggableComponentFactory = function (id, index) {
    var DraggableComponent = function (props) {
        return (_jsx(Draggable, __assign({ draggableId: id, index: index }, { children: function (provided, snapshot) { return (_jsx(TableRow
            // eslint-disable-next-line
            , __assign({ 
                // eslint-disable-next-line
                ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { style: getItemStyle(snapshot.isDragging, provided.draggableProps.style) }, props, { children: props.children }), void 0)); } }), void 0));
    };
    return DraggableComponent;
};
var DroppableComponentFactory = function (onDragEnd) {
    var DroppableComponent = function (props) {
        return (_jsx(DragDropContext, __assign({ onDragEnd: onDragEnd }, { children: _jsx(Droppable, __assign({ droppableId: "1", direction: "vertical" }, { children: function (provided) {
                    return (_jsxs(TableBody
                    // eslint-disable-next-line
                    , __assign({ 
                        // eslint-disable-next-line
                        ref: provided.innerRef }, provided.droppableProps, props, { children: [props.children, provided.placeholder] }), void 0));
                } }), void 0) }), void 0));
    };
    return DroppableComponent;
};
//
// Note: Drag-and-drop implemented based on notes from
// https://stackoverflow.com/questions/51839298/use-material-ui-table-with-react-beautiful-dnd
// and the linked gist https://github.com/hmarggraff/react-material-ui-table-row-drag-and-drop/blob/master/src/index.js
//
// June 2020 - I cant figure out why eslint fix insists on giving EditableTableRow a signature of <T extends Record<string, any>>
//
// eslint-disable-next-line @typescript-eslint/no-explicit-any
var EditableTable = function (props) {
    return (_jsx(DragDropContext, __assign({ onDragEnd: props.onDragEnd }, { children: _jsx(Droppable, __assign({ droppableId: "droppable" }, { children: function (provided, snapshot) { return (
            // eslint-disable-next-line
            _jsx(RootRef, __assign({ rootRef: provided.innerRef }, { children: _jsx(TableContainer, __assign({ style: getTableStyle(snapshot.isDraggingOver) }, { children: _jsxs(Table, __assign({ "aria-label": "Table" }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [props.dragReorder && (_jsx(TableCell, { children: _jsx(SwapVertIcon, {}, void 0) }, void 0)),
                                        props.labels.map(function (label, index) { return (_jsx(TableCell, { children: label }, index)); }),
                                        _jsx(TableCell, {}, void 0)] }, void 0) }, void 0),
                            _jsx(TableBody, __assign({ component: DroppableComponentFactory(props.onDragEnd) }, { children: props.rows.map(function (row, index) { return (_jsx(EditableTableRow, { component: DraggableComponentFactory(props.getRowId(row), index), dragReorder: true, editingButtons: props.editingButtons, row: row, names: props.names, index: index, onDelete: props.onDelete, onChanged: props.onChanged }, props.getRowId(row))); }) }), void 0)] }), void 0) }), void 0) }), void 0)); } }), void 0) }), void 0));
};
export { EditableTable };
