var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { connect } from "../../core/pluginService";
import { EditableTable } from "../EditableTable";
var useStyles = makeStyles({
    dialogContentText: {
        marginTop: "32px",
        marginBottom: "16px",
    },
});
var reorder = function (list, startIndex, endIndex) {
    var result = Array.from(list);
    var removed = result.splice(startIndex, 1)[0];
    result.splice(endIndex, 0, removed);
    return result;
};
var UserSettings = function (props) {
    var classes = useStyles();
    //
    // June 2020 - the material-ui component that calls this method defines event as any
    //
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var onNameChange = function (event) {
        props.userPrefsActions.setUsername(event.target.value);
    };
    //
    // June 2020 - the material-ui component that calls this method defines event as any
    //
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var onApiKeyChange = function (event) {
        props.userPrefsActions.setApiKey(event.target.value);
    };
    var onDragEnd = function (result) {
        if (!result.destination) {
            // dropped outside the list
            return;
        }
        var newRows = reorder(props.userPrefs.apps, result.source.index, result.destination.index);
        props.userPrefsActions.setApps(newRows);
    };
    var onRowAdd = function () {
        var newRows = props.userPrefs.apps.concat([
            {
                pluginId: "new plugin",
                text: "new button text",
            },
        ]);
        props.userPrefsActions.setApps(newRows);
    };
    var onRowDelete = function (index) {
        var newRows = props.userPrefs.apps.filter(function (candidate, candidateindex) { return candidateindex !== index; });
        props.userPrefsActions.setApps(newRows);
    };
    var onRowChange = function (index, row) {
        var newRows = props.userPrefs.apps.map(function (candidate, candidateIndex) {
            if (candidateIndex === index) {
                return row;
            }
            return candidate;
        });
        props.userPrefsActions.setApps(newRows);
    };
    connect(props.userPrefs.apiKey, props.userPrefs.username);
    if (!props.isOpen) {
        return null;
    }
    return (_jsxs(Dialog, __assign({ open: props.isOpen, keepMounted: true, onClose: props.close, scroll: "paper", "aria-labelledby": "alert-dialog-slide-title", "aria-describedby": "alert-dialog-slide-description" }, { children: [_jsx(DialogTitle, __assign({ id: "alert-dialog-slide-title" }, { children: "Settings" }), void 0),
            _jsxs(DialogContent, { children: [_jsxs(Grid, __assign({ container: true, spacing: 3 }, { children: [_jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(TextField, { margin: "dense", label: "Username", type: "text", fullWidth: true, value: props.userPrefs.username, onChange: onNameChange }, void 0) }), void 0),
                            _jsx(Grid, __assign({ item: true, xs: 6 }, { children: _jsx(TextField, { margin: "dense", label: "API Key", type: "text", fullWidth: true, value: props.userPrefs.apiKey, onChange: onApiKeyChange }, void 0) }), void 0)] }), void 0),
                    _jsx(DialogContentText, __assign({ id: "alert-dialog-slide-description", className: classes.dialogContentText }, { children: "Connect your apps to Tahiti" }), void 0),
                    _jsx(EditableTable, { dragReorder: true, editingButtons: false, rows: props.userPrefs.apps, names: ["pluginId", "text"], labels: ["Plugin", "Button Name"], onChanged: onRowChange, onDelete: onRowDelete, onDragEnd: onDragEnd, getRowId: function (row) { return row.pluginId; } }, void 0)] }, void 0),
            _jsx(DialogActions, { children: _jsx(Button, __assign({ onClick: onRowAdd, color: "primary" }, { children: "ADD PLUGIN" }), void 0) }, void 0)] }), void 0));
};
export { UserSettings };
