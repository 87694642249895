var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useReducer } from "react";
export var UserActionKind;
(function (UserActionKind) {
    UserActionKind["SetUser"] = "SetUser";
    UserActionKind["ClearUser"] = "ClearUser";
})(UserActionKind || (UserActionKind = {}));
var kInitialStoredUserStateString = localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem("userState");
var kInitialUserState = kInitialStoredUserStateString ? JSON.parse(kInitialStoredUserStateString) : {
    userPrivate: null,
    userPublic: null,
};
var UserReducer = function (state, action) {
    var newState = __assign(__assign({}, state), { userInfo: action.type === UserActionKind.SetUser ? action.payload : null });
    localStorage.setItem("userState", JSON.stringify(newState));
    return newState;
};
var UserContext = createContext([kInitialUserState, function () { return kInitialUserState; }]);
var UserProvider = function (_a) {
    var children = _a.children;
    return (_jsx(UserContext.Provider, __assign({ value: useReducer(UserReducer, kInitialUserState) }, { children: children }), void 0));
};
var useUser = function () { return useContext(UserContext); };
export { UserContext, UserProvider, useUser, };
