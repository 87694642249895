var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable */
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import RootRef from "@material-ui/core/RootRef";
import EditIcon from "@material-ui/icons/Edit";
import InboxIcon from "@material-ui/icons/Inbox";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable, } from "react-beautiful-dnd";
// fake data generator
var getItems = function (count) {
    return Array.from({ length: count }, function (v, k) { return k; }).map(function (k) { return ({
        id: "item-" + k,
        primary: "item " + k,
        secondary: k % 2 === 0 ? "Whatever for " + k : undefined,
    }); });
};
var reorder = function (list, startIndex, endIndex) {
    var result = Array.from(list);
    var removed = result.splice(startIndex, 1)[0];
    result.splice(endIndex, 0, removed);
    return result;
};
var getItemStyle = function (isDragging, draggableStyle) { return (__assign(__assign({}, draggableStyle), (isDragging && {
    background: "rgb(235,235,235)",
}))); };
var getListStyle = function (isDraggingOver) { return ({
//background: isDraggingOver ? 'lightblue' : 'lightgrey',
}); };
var DndExample = function () {
    var _a = useState(getItems(10)), items = _a[0], setItems = _a[1];
    var onDragEnd = function (result) {
        if (!result.destination) {
            // dropped outside the list
            return;
        }
        var newItems = reorder(items, result.source.index, result.destination.index);
        setItems(newItems);
    };
    // Normally you would split things out into separate components.
    // But in this example everything is done in one place for simplicity
    return (_jsx(DragDropContext, __assign({ onDragEnd: onDragEnd }, { children: _jsx(Droppable, __assign({ droppableId: "droppable" }, { children: function (provided, snapshot) { return (
            // eslint-disable-next-line
            _jsx(RootRef, __assign({ rootRef: provided.innerRef }, { children: _jsxs(List, __assign({ style: getListStyle(snapshot.isDraggingOver) }, { children: [items.map(function (item, index) { return (_jsx(Draggable, __assign({ draggableId: item.id, index: index }, { children: function (provided, snapshot) { return (_jsxs(ListItem, __assign({ ContainerProps: {
                                    // eslint-disable-next-line
                                    ref: provided.innerRef,
                                } }, provided.draggableProps, provided.dragHandleProps, { style: getItemStyle(snapshot.isDragging, provided.draggableProps.style) }, { children: [_jsx(ListItemIcon, { children: _jsx(InboxIcon, {}, void 0) }, void 0),
                                    _jsx(ListItemText, { primary: item.primary, secondary: item.secondary }, void 0),
                                    _jsx(ListItemSecondaryAction, { children: _jsx(IconButton, { children: _jsx(EditIcon, {}, void 0) }, void 0) }, void 0)] }), void 0)); } }), item.id)); }), provided.placeholder] }), void 0) }), void 0)); } }), void 0) }), void 0));
};
export { DndExample };
