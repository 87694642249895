var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var hasPersistance = true;
var storageKey = "userPrefs";
var defaultState = {
    apiKey: "abcd",
    username: "demo",
    apps: [
        { pluginId: "maya", text: "Add to Maya" },
        { pluginId: "photoshop", text: "Open in Photoshop" },
        { pluginId: "email", text: "Share via email" },
    ],
};
var persistedState = hasPersistance && window.localStorage.getItem(storageKey);
var defaultOrPersistedState = persistedState !== null ? JSON.parse(persistedState) : defaultState;
var actionsFactory = function (dispatch) { return ({
    assign: function (value) {
        dispatch({ type: "ASSIGN", payload: value });
    },
    setApiKey: function (value) {
        dispatch({ type: "SET_APIKEY", payload: value });
    },
    setApps: function (value) {
        dispatch({ type: "SET_APPS", payload: value });
    },
    setUsername: function (value) {
        dispatch({ type: "SET_USERNAME", payload: value });
    },
}); };
var reducer = function (state, action) {
    if (state === void 0) { state = defaultOrPersistedState; }
    var result = state;
    switch (action.type) {
        case "ASSIGN":
            if (action.payload) {
                result = JSON.parse(action.payload);
            }
            break;
        case "SET_APPS":
            result = __assign(__assign({}, state), { apps: action.payload });
            break;
        case "SET_USERNAME":
            result = __assign(__assign({}, state), { username: action.payload });
            break;
        case "SET_APIKEY":
            result = __assign(__assign({}, state), { apiKey: action.payload });
            break;
        default:
            break;
    }
    if (result !== state && hasPersistance) {
        window.localStorage.setItem(storageKey, JSON.stringify(result));
    }
    return result;
};
var selector = function (state) {
    return state[storageKey];
};
var userPrefsStore = {
    storageKey: storageKey,
    actionsFactory: actionsFactory,
    hasPersistance: hasPersistance,
    reducer: reducer,
    selector: selector,
};
export { 
// UserPrefsActionTypes,
userPrefsStore, };
