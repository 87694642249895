var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-script-url */
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React from "react";
var Title = function (props) {
    return (_jsx(Typography, __assign({ component: "h2", variant: "h6", color: "primary", gutterBottom: true }, { children: props.children }), void 0));
};
function createData(id, date, name, shipTo, paymentMethod, amount) {
    return { id: id, date: date, name: name, shipTo: shipTo, paymentMethod: paymentMethod, amount: amount };
}
var rows = [
    createData(0, "16 Mar, 2019", "Elvis Presley", "Tupelo, MS", "VISA ⠀•••• 3719", 312.44),
    createData(1, "16 Mar, 2019", "Paul McCartney", "London, UK", "VISA ⠀•••• 2574", 866.99),
    createData(2, "16 Mar, 2019", "Tom Scholz", "Boston, MA", "MC ⠀•••• 1253", 100.81),
    createData(3, "16 Mar, 2019", "Michael Jackson", "Gary, IN", "AMEX ⠀•••• 2000", 654.39),
    createData(4, "15 Mar, 2019", "Bruce Springsteen", "Long Branch, NJ", "VISA ⠀•••• 5919", 212.79),
];
var useStyles = makeStyles(function (theme) { return ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
}); });
var OrderList = function () {
    var classes = useStyles();
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: "Recent Orders" }, void 0),
            _jsxs(Table, __assign({ size: "small" }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Date" }, void 0),
                                _jsx(TableCell, { children: "Name" }, void 0),
                                _jsx(TableCell, { children: "Ship To" }, void 0),
                                _jsx(TableCell, { children: "Payment Method" }, void 0),
                                _jsx(TableCell, __assign({ align: "right" }, { children: "Sale Amount" }), void 0)] }, void 0) }, void 0),
                    _jsx(TableBody, { children: rows.map(function (row) { return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: row.date }, void 0),
                                _jsx(TableCell, { children: row.name }, void 0),
                                _jsx(TableCell, { children: row.shipTo }, void 0),
                                _jsx(TableCell, { children: row.paymentMethod }, void 0),
                                _jsx(TableCell, __assign({ align: "right" }, { children: row.amount }), void 0)] }, row.id)); }) }, void 0)] }), void 0),
            _jsx("div", __assign({ className: classes.seeMore }, { children: _jsx(Link, __assign({ color: "primary", href: "/" }, { children: "See more orders" }), void 0) }), void 0)] }, void 0));
};
export { OrderList };
