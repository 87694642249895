var kFolderNone = {
    files: [],
    folders: [],
    name: "",
    nestedFiles: [],
    path: "",
};
export { 
// IBufferedFile,
// IFolder,
// IFolderOrAsset,
kFolderNone, };
