var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { AppButtons } from "../AppButtons";
import { AssetImage } from "../AssetImage";
import { AssetTitle } from "../AssetTitle";
import { PaperWrapper } from "../PaperWrapper";
var useStyles = makeStyles(function (theme) { return ({
    buttonDiv: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    title: {
        padding: "20px",
    },
}); });
var WithinLazy = function (props) {
    var classes = useStyles();
    if (props.product.request) {
        props.product.request();
        props.product.request = undefined;
    }
    return (_jsxs(_Fragment, { children: [_jsx(AssetTitle, { className: classes.title, editable: true, product: props.product, modifyProduct: props.modifyProduct }, void 0),
            _jsxs(Grid, __assign({ container: true, direction: "row", justify: "center", alignItems: "flex-start", spacing: 3 }, { children: [_jsx(Grid, __assign({ item: true, md: 3 }, { children: _jsx(AssetImage, { product: props.product, lite: true }, void 0) }), void 0),
                    _jsx(Grid, __assign({ item: true, md: 9 }, { children: _jsxs(Grid, __assign({ container: true }, { children: [_jsx(Grid, __assign({ item: true, md: 12 }, { children: _jsx("div", __assign({ className: classes.buttonDiv }, { children: _jsx(AppButtons, { autoRender: true, downloadFbx: true, product: props.product, userPrefs: props.userPrefs }, void 0) }), void 0) }), void 0),
                                _jsx(Grid, __assign({ item: true, md: 12 }, { children: _jsx(props.component, __assign({}, props, { product: props.product }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0)] }, void 0));
};
var AssetCollection = function (props) {
    return (_jsx(_Fragment, { children: props.selectedHandles.map(function (handle, index) {
            var product = props.products[handle];
            if (!product) {
                return null;
            }
            return (_jsx(PaperWrapper, { children: _jsx(LazyLoadComponent, { children: _jsx(WithinLazy, { product: product, modifyProduct: props.modifyProduct, component: props.component, userPrefs: props.userPrefs }, void 0) }, void 0) }, handle + "-" + index));
        }) }, void 0));
};
export { AssetCollection };
