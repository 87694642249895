import * as crypto from "crypto";
//
// see https://stackoverflow.com/a/7480211/2827071
// and https://stackoverflow.com/a/59731180/2827071
//
var hmacsha1 = function (apiKey, username, plugin, handle) {
    return crypto
        .createHmac("sha1", apiKey)
        .update(username + ":" + plugin + ":" + handle)
        .digest("base64");
};
export { hmacsha1 };
