var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "@material-ui/core/Button";
import React from "react";
import { apiCmd } from "../../core/pluginService";
import { kFileFullFbx, kFilePreviewFbx, } from "../../core/types";
var AppButtons = function (props) {
    var fbxUrl = props.product.files[kFileFullFbx] &&
        props.product.files[kFileFullFbx].length > 0
        ? props.product.files[kFileFullFbx]
        : props.product.files[kFilePreviewFbx] &&
            props.product.files[kFilePreviewFbx].length > 0
            ? props.product.files[kFilePreviewFbx]
            : "";
    return (_jsxs(_Fragment, { children: [props.autoRender && (_jsx(Button, __assign({ variant: "contained", onClick: function () { return apiCmd("auto-render", props.product.handle); } }, { children: "Batch" }), void 0)),
            props.downloadFbx && fbxUrl && (_jsx(Button, __assign({ variant: "contained", color: "primary", href: fbxUrl, download: true }, { children: "Download FBX" }), void 0)),
            props.userPrefs.apps.map(function (app, index) { return (_jsx(Button, __assign({ variant: "contained", color: "primary", onClick: function () { return apiCmd(app.pluginId, props.product.handle); } }, { children: app.text }), index)); })] }, void 0));
};
export { AppButtons };
