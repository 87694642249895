var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
var useStyles = makeStyles(function (theme) { return ({
    checkboxRoot: {
        padding: "0px",
    },
    iconRoot: {
        minWidth: "24px",
    },
    row: {
        display: "flex",
    },
}); });
var categories = [
    [
        "character",
        "character:female",
        "character:male",
        "character:animal",
        "character:creature",
        "character:caricature",
        "character:costume",
        "character:famous",
        "character:creature:monster",
        "character:superhero",
        "character:animal:dinosaur",
        "character:animal:insect",
        "character:animal:marine",
        "character:animal:reptile",
        "character:animal:bird",
        "character:animal:fish",
        "character:animal:amphibian",
        "anatomy",
        "cartoony",
        "fantasy",
        "sci-fi",
        "prehistoric",
    ],
    [
        "prop",
        "set dressing",
        "furniture",
        "appliance",
        "kitchen",
        "living space",
        "dining",
        "bedroom",
        "bathroom",
        "office",
        "music",
        "medical",
        "laboratory",
        "garage",
        "garden",
        "environment",
        "household",
        "industrial",
        "technology",
        "electronics",
        "outdoor",
        "food",
        "transport",
        "sports",
        "tools",
        "weapon",
    ],
    [
        "location",
        "location:interior",
        "location:exterior",
        "location:interior:building",
        "location:interior:kitchen",
        "location:interior:cafe",
        "location:interior:office",
        "location:interior:medical",
        "location:exterior:city",
        "location:exterior:town",
        "location:exterior:rural",
        "location:foliage",
        "location:foliage:garden",
        "location:foliage:landscape",
        "location:foliage:potted",
    ],
    [
        "graphics",
        "icons",
        "icons:navigation",
        "icons:social media",
        "icons:design",
        "icons:travel",
        "icons:mobile",
        "icons:vr",
        "icons:ar",
        "emoji",
        "fx element",
        "scatter",
        "pre-vis",
        "printable",
        "product",
    ],
];
var AssetCategories = function (props) {
    var classes = useStyles();
    var toggle = function (product, category) {
        //
        // NOTE: category is either a single tag or a concatenated list of tags
        // separated by :'s. This function looks at the last tag in the list
        // and either adds all the tags in the list to the product or removes
        // just the last tag in the list from the product. If the last tag
        // in the list is blank the function does nothing.
        //
        var tags = category.split(":").filter(function (cat) {
            return cat.length > 0;
        });
        var lastTag = tags.length === 0 ? "" : tags[tags.length - 1];
        if (lastTag === "") {
            return;
        }
        var hasLastTag = product.tags.indexOf(lastTag) >= 0;
        var newProduct = __assign({}, product);
        if (hasLastTag) {
            newProduct.tags = product.tags.filter(function (candidate) { return candidate !== lastTag; });
        }
        else {
            var mangledTags_1 = [];
            product.tags.forEach(function (candidate) {
                mangledTags_1.push(candidate);
            });
            tags.forEach(function (candidate) {
                if (mangledTags_1.indexOf(candidate) === -1) {
                    mangledTags_1.push(candidate);
                }
            });
            newProduct.tags = mangledTags_1.sort();
        }
        props.modifyProduct(product, newProduct);
    };
    return (_jsx("div", __assign({ className: classes.row }, { children: categories.map(function (cats, catsIndex) { return (_jsx(Box, __assign({ width: 1 / categories.length }, { children: _jsx(List, { children: cats.map(function (category, catIndex) {
                    var labelId = "checkbox-list-label-" + props.product.handle + "-" + catsIndex + "-" + catIndex;
                    var tag = category.split(":").pop() || "";
                    return (_jsxs(ListItem, __assign({ button: true, dense: true, onClick: function () {
                            return toggle(props.product, category);
                        } }, { children: [_jsx(ListItemIcon, __assign({ className: classes.iconRoot }, { children: _jsx(Checkbox, { className: classes.checkboxRoot, edge: "start", checked: props.product.tags.indexOf(tag) >= 0, tabIndex: -1, disableRipple: true, inputProps: {
                                        "aria-labelledby": labelId,
                                    } }, void 0) }), void 0),
                            _jsx(ListItemText, { id: labelId, primary: tag }, void 0)] }), catIndex));
                }) }, void 0) }), catsIndex)); }) }), void 0));
};
export { AssetCategories };
