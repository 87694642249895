import { jsx as _jsx } from "react/jsx-runtime";
import React, { Fragment, useEffect, useState } from "react";
var IfExpanded = function (props) {
    var _a = useState(false), expanded = _a[0], setExpanded = _a[1];
    useEffect(function () {
        setExpanded(true);
        return function () {
            setExpanded(false);
        };
    }, []);
    if (expanded && props.children) {
        return _jsx(Fragment, { children: props.children }, void 0);
    }
    else {
        return _jsx("div", {}, void 0);
    }
};
export { IfExpanded };
