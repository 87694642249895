var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "../../config";
import "@webcomponents/custom-elements"; // required by model-viewer on older browsers
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import axios from "axios";
import React, { useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import { AssetCategories } from "../../../../pkgs/src/components/AssetCategories";
import { AssetImages } from "../../../../pkgs/src/components/AssetImages";
import { AssetMeta } from "../../../../pkgs/src/components/AssetMeta";
import { AssetSummary } from "../../../../pkgs/src/components/AssetSummary";
import { AssetTags } from "../../../../pkgs/src/components/AssetTags";
import { PostLoginHandler } from "../../../../pkgs/src/components/PostLoginHandler";
import { UserProvider } from "../../../../pkgs/src/components/UserProvider";
import { modifyAssetConfig } from "../../../../pkgs/src/core/libraryService";
import { reduxStore } from "../../../../pkgs/src/core/store";
import { AppLayout } from "../AppLayout";
import { AssetCollectionWrapped } from "../AssetCollectionWrapped";
import { DummyDetails } from "../DummyDetails";
import { FolderTree } from "../FolderTree";
import { SidebarItems } from "../SidebarItems";
var theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0948b3",
        },
        secondary: {
            main: "#a0e212",
        },
    },
    overrides: {
        MuiTab: {
            textColorSecondary: {
                color: "lightgray",
                "&:hover": {
                    color: "white",
                },
                // "&.Mui-selected": {
                //     color: "white",
                // }
            },
        },
    },
});
var a11yProps = function (index) {
    return {
        id: "scrollable-auto-tab-" + index,
        "aria-controls": "scrollable-auto-tabpanel-" + index,
    };
};
var TabPanel = function (props) {
    return (_jsx("div", __assign({ role: "tabpanel", hidden: props.value !== props.index, id: "scrollable-auto-tabpanel-" + props.index, "aria-labelledby": "scrollable-auto-tab-" + props.index }, { children: props.value === props.index && _jsx(Box, __assign({ p: 3 }, { children: props.children }), void 0) }), void 0));
};
var App = function () {
    var _a = useState(0), tabValue = _a[0], setTabValue = _a[1];
    var _b = useState([]), selectedHandles = _b[0], setSelectedHandles = _b[1];
    var _c = useState({}), products = _c[0], setProducts = _c[1];
    var modifyProduct = function (product, newProduct) {
        //
        // Optimistically record the change
        //
        var newProducts = __assign({}, products);
        newProducts[newProduct.handle] = newProduct;
        setProducts(newProducts);
        //
        // Propose the change to the server
        //
        var config = modifyAssetConfig(product, newProduct);
        axios(config)
            .then(function (result) {
            if (result.data !== product.handle) {
                //
                // server rejected the change
                //
                newProducts[newProduct.handle] = product;
                setProducts(newProducts);
                // TODO report the rejection to the user
                console.log("server rejected change to " + product.handle);
            }
        })
            .catch(function (reason) {
            console.log(reason);
        });
    };
    var handleTabChange = function (event, newValue) {
        setTabValue(newValue);
    };
    return (_jsx(Provider, __assign({ store: /* eslint-disable-line */ reduxStore }, { children: _jsx(UserProvider, { children: _jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsxs(BrowserRouter, { children: [_jsx(Route, { path: "/postlogin", exact: true, render: function (props) { return _jsx(PostLoginHandler, __assign({}, props), void 0); } }, void 0),
                        _jsx(Route, { exact: true, path: "/", render: function (props) { return (_jsxs(AppLayout, __assign({}, props, { title: "Dashboard" }, { children: [_jsxs(_Fragment, { children: [_jsx(Divider, {}, void 0),
                                            _jsx(SidebarItems, {}, void 0),
                                            _jsx(Divider, {}, void 0),
                                            _jsx(FolderTree, { products: products, selectedHandles: selectedHandles, setProducts: setProducts, setSelectedHandles: setSelectedHandles }, void 0)] }, void 0),
                                    _jsxs(Tabs, __assign({ value: tabValue, onChange: handleTabChange, indicatorColor: "secondary", textColor: "secondary", variant: "scrollable", scrollButtons: "auto", "aria-label": "scrollable auto tabs example" }, { children: [_jsx(Tab, __assign({ label: "Summary" }, a11yProps(0)), void 0),
                                            _jsx(Tab, __assign({ label: "Thumbs" }, a11yProps(1)), void 0),
                                            _jsx(Tab, __assign({ label: "Categories" }, a11yProps(2)), void 0),
                                            _jsx(Tab, __assign({ label: "Tags" }, a11yProps(3)), void 0),
                                            _jsx(Tab, __assign({ label: "Meta" }, a11yProps(4)), void 0),
                                            _jsx(Tab, __assign({ label: "Attributes" }, a11yProps(5)), void 0)] }), void 0),
                                    _jsxs(_Fragment, { children: [_jsx(TabPanel, __assign({ value: tabValue, index: 0 }, { children: _jsx(AssetCollectionWrapped, { products: products, selectedHandles: selectedHandles, modifyProduct: modifyProduct, component: AssetSummary }, void 0) }), void 0),
                                            _jsx(TabPanel, __assign({ value: tabValue, index: 1 }, { children: _jsx(AssetCollectionWrapped, { products: products, selectedHandles: selectedHandles, modifyProduct: modifyProduct, component: AssetImages }, void 0) }), void 0),
                                            _jsx(TabPanel, __assign({ value: tabValue, index: 2 }, { children: _jsx(AssetCollectionWrapped, { products: products, selectedHandles: selectedHandles, modifyProduct: modifyProduct, component: AssetCategories }, void 0) }), void 0),
                                            _jsx(TabPanel, __assign({ value: tabValue, index: 3 }, { children: _jsx(AssetCollectionWrapped, { products: products, selectedHandles: selectedHandles, modifyProduct: modifyProduct, component: AssetTags }, void 0) }), void 0),
                                            _jsx(TabPanel, __assign({ value: tabValue, index: 4 }, { children: _jsx(AssetCollectionWrapped, { products: products, selectedHandles: selectedHandles, modifyProduct: modifyProduct, component: AssetMeta }, void 0) }), void 0),
                                            _jsx(TabPanel, __assign({ value: tabValue, index: 5 }, { children: _jsx(DummyDetails, { all: false }, void 0) }), void 0)] }, void 0)] }), void 0)); } }, void 0),
                        _jsx(Route, { path: "/Other", render: function () { return _jsx("div", { children: "Hello world!" }, void 0); } }, void 0)] }, void 0) }), void 0) }, void 0) }), void 0));
};
export { App };
