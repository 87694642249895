var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import BarChartIcon from "@material-ui/icons/BarChart";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LayersIcon from "@material-ui/icons/Layers";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { UserActionKind, useUser, } from "../../../../pkgs/src/components/UserProvider";
import { UserSettingsWrapped } from "../../../../pkgs/src/components/UserSettingsWrapped";
import { getConfig } from "../../../../pkgs/src/pkgConfig";
var SidebarItems = function () {
    var _a, _b;
    var _c = useState(false), settingsVisible = _c[0], setSettingsVisible = _c[1];
    var _d = useUser(), user = _d[0], userAction = _d[1];
    var loginWithRedirect = function () {
        var targetRoute = "/";
        window.location.replace(getConfig().apiUrlBase + "/auth/prelogin?targetRoute=" + targetRoute);
    };
    var logout = function () {
        userAction({ type: UserActionKind.ClearUser });
        window.location.replace(getConfig().apiUrlBase + "/auth/prelogout");
    };
    var showSettings = function () {
        setSettingsVisible(true);
    };
    var hideSettings = function () {
        setSettingsVisible(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(UserSettingsWrapped, { isOpen: settingsVisible, close: hideSettings }, void 0),
            _jsxs(List, { children: [((_b = (_a = user === null || user === void 0 ? void 0 : user.userInfo) === null || _a === void 0 ? void 0 : _a.userPrivate) === null || _b === void 0 ? void 0 : _b.loggedIn) ? (_jsxs(ListItem, __assign({ button: true, onClick: function () { return logout(); } }, { children: [_jsx(ListItemIcon, { children: _jsx(DashboardIcon, {}, void 0) }, void 0),
                            _jsx(ListItemText, { primary: "Logout" }, void 0)] }), void 0)) : (_jsxs(ListItem, __assign({ button: true, onClick: function () { return loginWithRedirect(); } }, { children: [_jsx(ListItemIcon, { children: _jsx(DashboardIcon, {}, void 0) }, void 0),
                            _jsx(ListItemText, { primary: "Login" }, void 0)] }), void 0)),
                    _jsxs(ListItem, __assign({ button: true, onClick: showSettings }, { children: [_jsx(ListItemIcon, { children: _jsx(DashboardIcon, {}, void 0) }, void 0),
                            _jsx(ListItemText, { primary: "Settings" }, void 0)] }), void 0),
                    _jsxs(ListItem, __assign({ button: true }, { component: NavLink, to: "/Other" }, { children: [_jsx(ListItemIcon, { children: _jsx(BarChartIcon, {}, void 0) }, void 0),
                            _jsx(ListItemText, { primary: "Reports" }, void 0)] }), void 0),
                    _jsxs(ListItem, __assign({ button: true }, { component: NavLink, to: "/Other" }, { children: [_jsx(ListItemIcon, { children: _jsx(LayersIcon, {}, void 0) }, void 0),
                            _jsx(ListItemText, { primary: "Integrations" }, void 0)] }), void 0)] }, void 0)] }, void 0));
};
export { SidebarItems };
