var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import FormData from "form-data";
import { getConfig } from "../../pkgConfig";
import { kFileSrcNone, kProductEmpty, kSearchResultsZero, } from "../types";
import { useDataApi } from "./axiosHelper";
import { kFolderNone } from "./types";
var searchConfig = function (query, limit, offset, info) {
    var url = getConfig().apiUrlBase + "/v1.0/api/library/search?query=" + query + "&limit=" + limit + "&offset=" + offset;
    var config = __assign({ url: url, method: "get", headers: {
            authorization: "Bearer demo",
            "content-type": "application/json",
        } }, info);
    return config;
};
var useSearchApi = function (query, limit, offset, info) {
    var initialData = __assign(__assign({}, kSearchResultsZero), { query: query });
    return useDataApi(searchConfig(query, limit, offset, info), initialData);
};
var getDetailsConfig = function (handle, info) {
    var url = getConfig().apiUrlBase + "/v1.0/api/library/details?handle=" + handle;
    var config = __assign({ url: url, method: "get", headers: {
            authorization: "Bearer demo",
            "content-type": "application/json",
        } }, info);
    return config;
};
var useGetDetailsApi = function (handle, info) {
    var initialData = kProductEmpty(handle);
    return useDataApi(getDetailsConfig(handle, info), initialData);
};
var addAssetConfig = function (asset, info) {
    var url = getConfig().apiUrlBase + "/v1.0/api/library/asset";
    var config = __assign({ url: url, method: "post", withCredentials: true, headers: {
            authorization: "Bearer demo",
            "content-type": "application/json",
        }, data: asset }, info);
    return config;
};
var useAddAssetApi = function (asset, info) {
    var initialData = "";
    return useDataApi(addAssetConfig(asset, info), initialData);
};
var modifyAssetConfig = function (assetOld, assetNew, info) {
    var url = getConfig().apiUrlBase + "/v1.0/api/library/asset";
    var config = __assign({ url: url, method: "put", withCredentials: true, headers: {
            authorization: "Bearer demo",
            "content-type": "application/json",
        }, data: {
            old: assetOld,
            new: assetNew,
        } }, info);
    return config;
};
var useModifyAssetApi = function (assetOld, assetNew, info) {
    var initialData = "";
    return useDataApi(modifyAssetConfig(assetOld, assetNew, info), initialData);
};
var deleteAssetConfig = function (handle, info) {
    var url = getConfig().apiUrlBase + "/v1.0/api/library/asset?handle=" + handle;
    var config = __assign({ url: url, method: "delete", withCredentials: true, headers: {
            authorization: "Bearer demo",
            "content-type": "application/json",
        } }, info);
    return config;
};
var useDeleteAssetApi = function (handle, info) {
    var initialData = "";
    return useDataApi(deleteAssetConfig(handle, info), initialData);
};
var setImageConfig = function (handle, identifier, files, info) {
    var url = getConfig().apiUrlBase + "/v1.0/api/library/image?handle=" + handle + "&identifier=" + identifier;
    var formData = new FormData();
    formData.append("poster", files.poster[0].buffer, {
        filename: files.poster[0].originalname,
        contentType: files.poster[0].mimetype,
        knownLength: files.poster[0].size,
    });
    formData.append("thumb", files.thumb[0].buffer, {
        filename: files.thumb[0].originalname,
        contentType: files.thumb[0].mimetype,
        knownLength: files.thumb[0].size,
    });
    var config = __assign({ url: url, method: "post", headers: formData.getHeaders(), data: formData }, info);
    return config;
};
var useSetImageApi = function (handle, identifier, files, info) {
    var initialData = "";
    return useDataApi(setImageConfig(handle, identifier, files, info), initialData);
};
var uploadFileConfig = function (handle, identifier, extension, files, info) {
    var url = getConfig().apiUrlBase + "/v1.0/api/library/file?handle=" + handle + "&identifier=" + identifier + "&extension=" + extension;
    var formData = new FormData();
    formData.append("file", files.file[0].buffer, {
        filename: files.file[0].originalname,
        contentType: files.file[0].mimetype,
        knownLength: files.file[0].size,
    });
    var config = __assign({ url: url, method: "post", headers: formData.getHeaders(), data: formData }, info);
    return config;
};
var useUploadFileApi = function (handle, identifier, extension, files, info) {
    var initialData = kFileSrcNone;
    return useDataApi(uploadFileConfig(handle, identifier, extension, files, info), initialData);
};
var getFolderConfig = function (path, info) {
    var url = getConfig().apiUrlBase + "/v1.0/api/library/folder?path=" + path;
    var config = __assign({ url: url, method: "get", withCredentials: true, headers: {
            authorization: "Bearer demo",
            "content-type": "application/json",
        } }, info);
    return config;
};
var useGetFolderApi = function (path, info) {
    var initialData = kFolderNone;
    return useDataApi(getFolderConfig(path, info), initialData);
};
var addFolderConfig = function (path, folder, info) {
    var url = getConfig().apiUrlBase + "/v1.0/api/library/addFolder?path=" + path;
    var config = __assign({ url: url, method: "get", withCredentials: true, headers: {
            authorization: "Bearer demo",
            "content-type": "application/json",
        }, data: folder }, info);
    return config;
};
var useAddFolderApi = function (path, folder, info) {
    var initialData = false;
    return useDataApi(addFolderConfig(path, folder, info), initialData);
};
var modifyFolderConfig = function (path, folderOld, folderNew, info) {
    var url = getConfig().apiUrlBase + "/v1.0/api/library/modifyFolder?path=" + path;
    var config = __assign({ url: url, method: "get", withCredentials: true, headers: {
            authorization: "Bearer demo",
            "content-type": "application/json",
        }, data: {
            old: folderOld,
            new: folderNew,
        } }, info);
    return config;
};
var useModifyFolderApi = function (path, folderOld, folderNew, info) {
    var initialData = false;
    return useDataApi(modifyFolderConfig(path, folderOld, folderNew, info), initialData);
};
var deleteFolderConfig = function (path, info) {
    var url = getConfig().apiUrlBase + "/v1.0/api/library/deleteFolder?path=" + path;
    var config = __assign({ url: url, method: "post", withCredentials: true, headers: {
            authorization: "Bearer demo",
            "content-type": "application/json",
        } }, info);
    return config;
};
var useDeleteFolderApi = function (path, info) {
    var initialData = false;
    return useDataApi(deleteFolderConfig(path, info), initialData);
};
export { addAssetConfig, addFolderConfig, deleteAssetConfig, deleteFolderConfig, getDetailsConfig, getFolderConfig, modifyAssetConfig, modifyFolderConfig, searchConfig, setImageConfig, uploadFileConfig, useAddAssetApi, useAddFolderApi, useDeleteAssetApi, useDeleteFolderApi, useGetDetailsApi, useGetFolderApi, useModifyAssetApi, useModifyFolderApi, useSearchApi, useSetImageApi, useUploadFileApi, };
