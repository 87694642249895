var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import { tagsAll } from "./tags";
var AssetTags = function (props) {
    var _a = useState(""), inputValue = _a[0], setInputValue = _a[1];
    var setTags = function (tags) {
        var newProduct = __assign({}, props.product);
        var mangledTags = [];
        tags.forEach(function (candidate) {
            candidate.split(",").forEach(function (candidate2) {
                var tag = candidate2
                    .trim()
                    .toLowerCase()
                    .replace(/\s/g, "-")
                    .replace(/[^0-9a-z-]/g, "")
                    .replace(/-+/g, "-")
                    .replace(/^-+/, "")
                    .replace(/-+$/, "");
                if (tag) {
                    mangledTags.push(tag);
                }
            });
        });
        newProduct.tags = Array.from(new Set(mangledTags)).sort();
        props.modifyProduct(props.product, newProduct);
    };
    if (!props.product) {
        return null;
    }
    return (_jsx(Autocomplete, { autoComplete: true, disableClearable: true, freeSolo: true, fullWidth: true, getOptionLabel: function (option) { return option; }, getOptionSelected: function (option, value) {
            return option === value;
        }, inputValue: inputValue, multiple: true, onChange: function (event, newValue) {
            setTags(newValue);
        }, onInputChange: function (event, newInputValue) {
            setInputValue(newInputValue
                .toLowerCase()
                .replace(/;/g, ",")
                .replace(/\s/g, "-")
                .replace(/[^0-9a-z-,]/g, "")
                .replace(/-+/g, "-")
                .replace(/,+/g, ",")
                .replace(/^-/, "")
                .replace(/,-/g, ","));
        }, openOnFocus: true, options: tagsAll, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { placeholder: "add searchable tags", variant: "outlined" }), void 0)); }, renderTags: function (value, getTagProps) {
            return value.map(function (option, index) { return (_jsx(Chip, __assign({ variant: "outlined", label: option }, getTagProps({ index: index })), index)); });
        }, value: props.product.tags }, void 0));
};
export { AssetTags };
