import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { PaperWrapper } from "../../../../pkgs/src/components/PaperWrapper";
import { DndExample } from "../DndExample";
import { OrderList } from "../OrderList";
var DummyDetails = function (props) {
    return (_jsxs(PaperWrapper, { children: [props.all && _jsx(OrderList, {}, void 0),
            !props.all && _jsx(DndExample, {}, void 0)] }, void 0));
};
export { DummyDetails };
